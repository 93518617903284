import React from "react";
import { motion } from "framer-motion";
import { variants } from "./Home";

const Settings = () => {
  return (
    <>
      <h1> Settings </h1>
      {/* <select>
        <option value="volvo">Volvo</option>
        <option value="saab">Saab</option>
        <option value="opel">Opel</option>
        <option value="audi">Audi</option>
      </select> */}
    </>
  );
};

export default Settings;
